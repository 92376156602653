// src/components/Timeline/EventCard.js
import React, { useState, useEffect } from 'react';
import { 
  Card,
  Typography, 
  Box, 
  Button,
  List,
  ListItem,
  ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import axiosInstance from '../../api/axiosInstance';

const EventCard = ({ event }) => {
  const navigate = useNavigate();
  const handleClick = () => navigate(`/events/${event.event_type.toLowerCase()}/${event.id}`);
  const [scripts, setScripts] = useState(event.scripts || []);

  const isGame = event.event_type === 'Game';

  // Formatierung des Datums in deutsches Format
  const formattedDate = format(new Date(event.date), "dd.MM.yyyy 'um' HH:mm", { locale: de });

  useEffect(() => {
    const fetchScripts = async () => {
      try {
        let response;
        if (isGame) {
          response = await axiosInstance.get(`/games/${event.id}/scripts/`);
        } else {
          response = await axiosInstance.get(`/practices/${event.id}/scripts/`);
        }
        setScripts(response.data);
      } catch (error) {
        console.error('Fehler beim Abrufen der Scripts:', error);
      }
    };
    if (!event.scripts || event.scripts.length === 0) {
      fetchScripts();
    }
  }, [event.id, event.event_type]);

  return (
    <Card variant="outlined"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        marginBottom: 2,
        borderLeft: `5px solid ${isGame ? '#f44336' : '#4caf50'}`, // Rote Farbe für Games, grün für Practices
        backgroundColor: isGame ? '#fff3f2' : '#f2f9f2',
      }}  >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        {isGame ? (
          <SportsSoccerIcon fontSize="large" color="error" />
        ) : (
          <FitnessCenterIcon fontSize="large" color="success" />
        )}
        <Box sx={{ marginLeft: 2 }}>
          <Typography variant="h6">{event.name}</Typography>
          <Typography variant="body1" color="textSecondary">
            {isGame ? `Spiel gegen ${event.opponent}` : `Training für ${event.team.name}`}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {formattedDate} - {event.location}
          </Typography>
        </Box>
        <Box sx={{ marginLeft: 'auto' }}>
          <Button variant="contained" color="primary" onClick={handleClick}>
            Details ansehen
          </Button>
        </Box>
      </Box>
       {/* Scripts */}
       <Typography variant="subtitle1" gutterBottom>
        Scripts
      </Typography>
      <List>
        {scripts.map((script) => (
          <ListItem
            key={script.id}
            secondaryAction={
              <>
                <Button
                  size="small"
                  variant="outlined"
                  color="success"
                  onClick={() => navigate(`/scripts/${script.id}/offense`)}
                >
                  Offense
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  color="error"
                  sx={{ ml: 1 }}
                  onClick={() => navigate(`/scripts/${script.id}/defense`)}
                >
                  Defense
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  sx={{ ml: 1 }}
                  onClick={() => navigate(`/scripts/${script.id}/specialteams`)}
                >
                  Special Teams
                </Button>
              </>
            }
          >
            <ListItemText primary={script.name} />
          </ListItem>
        ))}
      </List>

    </Card>
  );
};

export default EventCard;
