// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { CssBaseline, Container, Box } from '@mui/material';
import Header from './components/Header';
import { fetchOrganisations } from './api/organisationsApi';
import TeamEventsPage from './pages/TeamEventsPage';
import EventTimeline from './components/Timeline/EventTimeline';
import AddEventModal from './components/Timeline/AddEventModal';
import axiosInstance from './api/axiosInstance';
import useFetchEvents from './hooks/useFetchEvents'
import ContainerPageHeader from './components/ContainerPageHeader';

function App() {
  const [selectedOrganisation, setSelectedOrganisation] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [organisations, setOrganisations] = useState([]);

  const { events, loading, error, refreshEvents } = useFetchEvents(selectedTeam?.id);

  

  useEffect(() => {
    const loadOrganisations = async () => {
      try {
        const data = await fetchOrganisations();
        setOrganisations(data);
        if (data.length > 0) {
          setSelectedOrganisation(data[0]);
          if (data[0].teams.length > 0) {
            setSelectedTeam(data[0].teams[0]);
          }
        }
      } catch (error) {
        console.error('Error fetching organisations:', error);
      }
    };
    loadOrganisations();
  }, []);

  const handleSelectTeam = (organisation, team) => {
    setSelectedOrganisation(organisation);
    setSelectedTeam(team);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <CssBaseline />

      {/* Header */}
      <Header
        selectedOrganisation={selectedOrganisation}
        selectedTeam={selectedTeam}
        organisations={organisations}
        onSelectTeam={handleSelectTeam}
      />

      {/* Main Content */}
      <Container sx={{ flexGrow: 1, padding: 2, marginTop: 2 }}>
        {/* Inhalte und Routen */}
        <Routes>
          <Route 
            path="/" 
            element={<TeamEventsPage selectedTeam={selectedTeam} />} 
            />
        </Routes>
      </Container>
    </Box>
  );
}

export default App;
