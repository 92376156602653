// OrganisationDropdown.js
import React, { useState } from 'react';
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Collapse,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/system';

const DropdownContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: '250px', // Passe die maximale Breite an
  [theme.breakpoints.up('sm')]: {
    maxWidth: '300px', // Erhöhte Breite für größere Bildschirme
  },
}));

const DropdownMenu = styled('div')(({ theme, isOpen }) => ({
  position: 'absolute',
  top: '100%',
  left: 0,
  width: '100%',
  maxHeight: isOpen ? '300px' : 0,
  overflowY: 'auto',
  color: 'black',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  borderRadius: theme.shape.borderRadius,
  zIndex: 10,
  transition: 'max-height 0.3s ease',
}));

function OrganisationDropdown({ selectedOrganisation, selectedTeam, organisations, onSelectTeam }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [expandedOrganisation, setExpandedOrganisation] = useState(null);

  const handleOrganisationClick = (orgId) => {
    setExpandedOrganisation(expandedOrganisation === orgId ? null : orgId);
  };

  const isSelectedTeam = (team) => selectedTeam && team.id === selectedTeam.id;

  const handleToggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  return (
    <DropdownContainer>
      <Button
        variant="contained"
        onClick={handleToggleDropdown}
        sx={{
          width: '100%',
          textTransform: 'none',
          padding: '6px 12px', // Kompakter für mobile
        }}
      >
        {selectedTeam ? selectedTeam.name : 'Team wählen'}
        {isDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>

      <DropdownMenu isOpen={isDropdownOpen}>
        <List component="nav">
          {organisations.map((org) => (
            <React.Fragment key={org.id}>
              <ListItem button onClick={() => handleOrganisationClick(org.id)}>
                <ListItemText
                  primary={<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{org.name}</Typography>}
                />
                {expandedOrganisation === org.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItem>

              <Collapse in={expandedOrganisation === org.id} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {org.teams.map((team) => (
                    <ListItem
                      button
                      key={team.id}
                      onClick={() => onSelectTeam(org, team)}
                      selected={isSelectedTeam(team)}
                    >
                      <ListItemText primary={team.name} />
                      {isSelectedTeam(team) && <CheckCircleIcon color="primary" />}
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          ))}
        </List>
      </DropdownMenu>
    </DropdownContainer>
  );
}

export default OrganisationDropdown;
