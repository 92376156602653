// Header.js
import React from 'react';
import { AppBar, Toolbar, Box } from '@mui/material';
import OrganisationDropdown from './OrganisationDropdown';

const Header = ({ selectedOrganisation, selectedTeam, organisations, onSelectTeam }) => {
  return (
    <AppBar position="static" color="primary" sx={{ padding: 1 }}>
      <Toolbar sx={{ justifyContent: 'center', display: 'flex', gap: 2 }}>
        {/* Organisation/Team Dropdown */}
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
          <OrganisationDropdown
            selectedOrganisation={selectedOrganisation}
            selectedTeam={selectedTeam}
            organisations={organisations}
            onSelectTeam={onSelectTeam}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
