// src/components/Timeline/EventTimeline.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import EventCard from './EventCard';

const EventTimeline = ({ events }) => {
  const sortedEvents = events.sort((a, b) => new Date(a.date) - new Date(b.date));

  return (
    <>
      {sortedEvents.map((event) => (
        <EventCard key={event.id} event={event} />
      ))}
    </>
  );
};

export default EventTimeline;
