// AuthComponent.js

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { 
    Container, 
    Box, 
    Typography, 
    TextField, 
    Button, 
    Alert,
    CircularProgress 
} from '@mui/material';

function AuthComponent({ setIsLoggedIn }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null); // Reset error before a new attempt

        try {
            const response = await axios.post('https://football-tracker.de/api/token-auth/', { username, password });
            localStorage.setItem('token', response.data.token);

            setIsLoggedIn(true);
            navigate('/'); // Redirect after successful login
        } catch (error) {
            setError('Fehler beim Einloggen. Bitte überprüfen Sie Ihre Anmeldedaten.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container maxWidth="xs" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
            <Box sx={{ width: '100%', padding: 3, boxShadow: 3, borderRadius: 2, backgroundColor: 'background.paper' }}>
                <Typography variant="h4" component="h1" gutterBottom align="center">
                    Login
                </Typography>

                <form onSubmit={handleSubmit} style={{ marginTop: 2 }}>
                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {error}
                        </Alert>
                    )}

                    <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        type="email"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />

                    <TextField
                        label="Passwort"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />

                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                            disabled={loading}
                            startIcon={loading && <CircularProgress size={20} color="inherit" />}
                        >
                            {loading ? 'Einloggen...' : 'Einloggen'}
                        </Button>
                    </Box>
                </form>
            </Box>
        </Container>
    );
}

export default AuthComponent;
