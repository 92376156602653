import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './assets/theme'; // Importiere das Theme
import AuthComponent from './components/AuthComponent';

const root = ReactDOM.createRoot(document.getElementById('root'));
function Main() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Überprüfen, ob ein Token vorhanden ist
  useEffect(() => {
    const token = localStorage.getItem('token');  
    console.log("Token gefunden:", token);  // Debugging
    setIsLoggedIn(!!token); // Setzt isLoggedIn auf true, wenn ein Token vorhanden ist
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={!isLoggedIn ? <AuthComponent setIsLoggedIn={handleLogin} /> : <Navigate to="/" />}
        />
        <Route
          path="/*"
          element={
            isLoggedIn ? (
              <App onLogout={handleLogout} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Main />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
